import React from 'react';
import BookingsTable from '../components/BookingsTable';
import Query from 'src/components/GraphQL/components/Query';
import LoadingSpinner from 'src/components/LoadingSpinner';
import gql from 'graphql-tag';
import moment from 'moment';
import Layout from 'src/layouts';
// import { reduceObjValues } from '../components/BookingsTable/util';

const GUESTS_RESERVATIONS = gql`
  query GuestsReservations(
    $where: GuestReservationWhereInput
    # $reservationCreateDate_gte: Date
    # $reservationCreateDate_lte: Date
    # # $checkIn_gte: Date
    $first: Int!
    $skip: Int!
    $orderBy: [GuestReservationOrderByInput]
  ) {
    guestReservationConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
      edges {
        node {
          confirmationStatus
          welcomeMessageDateSent
          reference
          id
          externalConversationUrl
          reservationFormToken {
            token
          }
          guestStaysAtThisProperty
          guestStaysAtOtherProperties
          reservationCreateDate
          checkInDate: checkIn
          checkOutDate: checkOut
          checkInHour
          checkOutHour
          property {
            key
            maxAdults
          }
          guest {
            guestId: id
            firstName
            lastName
          }
          status
          adults
          children
          pets
          visitors
          agreementSigned
          eventType
          mobilityChallenged
          largeVehicles
          resortAccess
          vehicles
          redFlags {
            id
            label
            type
          }
          finalizationSteps {
            id
            label
            readOnly
          }
        }
      }
    }
  }
`;

const RecentBookings = () => {
  const today = new Date();

  return (
    <Layout hideMainOverflow={true} metaTitle={'Recent Bookings'}>
      <Query
        variables={{
          where: {
            status: 'BOOKING',
            reservationCreateDate_gte: moment(today)
              .subtract(45, 'days')
              .format('YYYY-MM-DD'),
            checkIn_gte: moment(today).add(1, 'days').format('YYYY-MM-DD'),
          },
          // checkIn_gte: today,
          first: 250,
          skip: 0,
          orderBy: 'reservationCreateDate_DESC',
        }}
        notifyOnNetworkStatusChange={true}
        query={GUESTS_RESERVATIONS}
        // skip={searchTerm}
        fetchPolicy="no-cache"
      >
        {({
          loading,
          error,
          data,
          refetch: refetchAllReservations,
          networkStatus,
        }) => {
          const edges = data?.guestReservationConnection?.edges;

          const isRefetching = networkStatus === 4;

          // const topLevelDataObjects = edges
          //   ? edges.reduce((acc, edge) => {
          //       acc.push(reduceObjValues(edge?.node));
          //       return acc;
          //     }, [])
          //   : null;

          return (
            <>
              {edges ? (
                <BookingsTable
                  bookingType={'recent-bookings'}
                  isRefetching={isRefetching}
                  tableData={edges}
                  refetchAllReservations={refetchAllReservations}
                />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              )}
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default RecentBookings;
